@font-face
{
	font-family: "Proxima-nova";
	src: url("https://use.typekit.net/nks7irh.css") format("truetype");
}
p, h1, h2, h3, h4, h5, h6
{
	color: #4F5C73;
}
button
{
	display:        inline-block;
	font-weight:    600;
	text-align:     center;
	text-transform: uppercase;
	vertical-align: middle;
	word-wrap:      break-word;
	white-space:    normal;
	border-radius:  .25rem;
	border:         solid #FAE900 0px;
	box-shadow:     0 2px 5px 0 rgba(0,0,0,.32),0 2px 10px 0 rgba(0,0,0,.24);
	padding:        .8rem 2.6rem;
	margin:         .5rem;
	transition:     color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
h3.header
{
	font-size: 1.0em;
	position:  relative;
	top:       -30px;
}
p
{
	font-size: 0.8em;
}
p.footer
{
	position: relative;
	top:      50px;
}
.align-right
{
	text-align: right;
}
.hidden
{
	display: none;
}
/*****************************************************************************
 *                       FIFSRegistration - Main block                       *
 *****************************************************************************/
.FIFSRegistration
{
	display:             flex;
	flex-direction:      column;
	align-items:         center;
	justify-content:     center;
	text-align:          center;
	height:              360px;
	width:               360px;
	padding:             10px;
	box-shadow:          0 27px 24px 0 rgba(0,0,0,.2),0 40px 77px 0 rgba(0,0,0,.22);
	background:          url("../assets/rlc-logo.svg") 0 0  no-repeat;
	background-position: -10% 175%;
	background-size:     60%;
}
.FIFSRegistration,
.FIFSRegistration form input,
.FIFSRegistration form label
{
	font-family: "Proxima-Nova", sans-serif;
	font-size:   1.25rem;
}

/*****************************************************************************
 *                          FIFSRegistration - Form                          *
 *****************************************************************************/
.FIFSRegistration form .inputgroup
{
	display:       flex;
	width:         100%;
	padding:       .3rem 0;
	margin:        .5rem 0;
	border-bottom: 2px solid #11B15E;
}
.FIFSRegistration form
{
	width: 100%;
}
.FIFSRegistration form .inputgroup *:not(:only-child)
{
	width:   50%;
}
.FIFSRegistration form input:only-child
{
	padding: 0 1rem;
}
.FIFSRegistration form input
{
	width:   100%;
	color:   #11B15E;
	padding: 0;
	border:  none;
}
.FIFSRegistration form label
{
	display:     inline-flex;
	text-align:  left;
	align-items: center;
}
.FIFSRegistration form label::before
{
	content: '.';
}
.FIFSRegistration form button
{
	cursor:           pointer;
	color:            #5d4b00;
	background-color: #F9C300;
}
.FIFSRegistration form button img
{
	margin: -.4rem;
	height: 1.4rem;
	width: 1.4em;
}

/*****************************************************************************
 *                    FIFSRegistration - Form:not(.valid)                    *
 *****************************************************************************/
.FIFSRegistration form:not(.valid) input
{
	color: #EF5353;
}
.FIFSRegistration form:not(.valid) .inputgroup
{
	border-bottom: 2px solid #EF5353;
}
.FIFSRegistration form:not(.valid) button[type=submit]
{
	cursor:           not-allowed;
	pointer-events:   none;
	background-color: #fff;
}

/*****************************************************************************
 *                     FIFSRegistration - Video preview                      *
 *****************************************************************************/
.FIFSRegistration form video
{
	width:            320px;
	height:           240px;
	background-color: #cccccc;
}
