body
{
	font-family:             "Proxima-Nova", sans-serif;
	background:              #FFFFFF;
	margin:                  0;
	-webkit-font-smoothing:  antialiased;
	-moz-osx-font-smoothing: grayscale;

	display:         flex;
	flex-direction:  column;
	height:          100vh;
	width:           100vw;
	align-items:     center;
	justify-content: center;
}

code
{
	font-family: "Proxima-Nova", sans-serif;
}
